import { useEffect, useState } from 'react';
import './App.css';
import LoadingScreen from './components/loading/LoadingScreen';
import Landing from './routes/Landing';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingAnimFinished, setLoadingAnimFinish] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // looks nicer than just flashing the loading page really fast, so we display it for a minimum of 1 second
      setLoadingAnimFinish(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="App">
      <div className={isLoaded && loadingAnimFinished ? 'hidden' : 'visible'}>
        <LoadingScreen />
      </div>
      <div className={isLoaded && loadingAnimFinished ? 'visible' : 'hidden'}>
        <Landing onReady={() => setIsLoaded(true)} />
      </div>
    </div>
  );
}

export default App;
