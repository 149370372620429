interface ButtonProps {
  disabled?: boolean;
  hidden?: boolean;
}

function PrimaryButton(props: ButtonProps) {
  const disabled = props.disabled ? props.disabled === true : false;

  // TODO: we should revisit the hover effect color later
  // I think having the same color for onHover and disabled buttons is bad UX
  return (
    <button
      disabled={disabled}
      className="w-fit whitespace-nowrap rounded-lg bg-green-400 px-9 py-2 font-extrabold tracking-[0.7px] text-gray-900 hover:bg-green-900 disabled:bg-green-900"
    >
      Coming soon!
    </button>
  );
}

function SecondaryButton(props: ButtonProps) {
  const hidden = props.hidden ? props.hidden === true : false;

  // TODO: onHover background color was chosen arbitrarily, need to get a background
  // color that follows design spec
  return (
    <button
      hidden={hidden}
      className="w-fit whitespace-nowrap rounded-lg border border-gray-300 px-9 py-2 tracking-[0.7px] text-gray-300 hover:border-gray-500"
    >
      Add to Chrome
    </button>
  );
}

export { PrimaryButton, SecondaryButton };
