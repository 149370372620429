import { PrimaryButton, SecondaryButton } from '../components/common/Buttons';
import Logo from '../components/logo/Logo';

interface LandingProps {
  onReady?: () => void;
}

function Landing(props: LandingProps) {
  // TODO: should add a timeout for situations where the video is taking too long to load

  const ready = () => {
    console.debug('finished loading video');
    if (props.onReady) {
      props.onReady();
    }
  };

  const onVideoError = () => {
    console.error('video download failed!');
    // TODO: calling onReady here so users can still use the page in the event the video fails to load,
    // but this will result in a slightly broken page. we should adjust the layout of the page accordingly
    // when this happens
    if (props.onReady) {
      props.onReady();
    }
  };

  return (
    <div style={{ width: '100%' }} className="xl:flex xl:flex-row">
      {/* left side */}
      <div className="relative h-fit min-h-screen w-full bg-gray-900 xl:w-1/2">
        <div className="flex h-screen min-h-fit w-full flex-col justify-between overflow-auto px-16 py-10 sm:px-32 sm:py-20">
          <div>
            <Logo />
          </div>

          <div className="text-left text-white">
            <div className="mt-12 text-6xl/[70px] font-bold">
              The Know
              <br /> Your <span className="text-green-400">Network</span>
              <br /> Platform
            </div>

            <div className="mt-12 text-left text-lg font-bold tracking-[1px]">
              Connect with the most reputable people and companies on LinkedIn.
            </div>

            <div className="mt-12 flex w-full flex-col sm:flex-row">
              <PrimaryButton disabled />
              <span className="h-3 sm:h-0 sm:w-6" />
              <SecondaryButton hidden />
            </div>
          </div>

          {/* empty div to have justify-between align the above hero text in the center */}
          <div></div>
        </div>
      </div>

      {/* right side */}
      <div className="relative min-h-screen w-full bg-gray-900 text-white xl:w-1/2">
        <div className="absolute h-full w-full">
          <video
            preload="auto"
            onLoadStart={() => console.debug('video download start')}
            onLoadedData={() => console.debug('partial video download')}
            onCanPlayThrough={() => ready()}
            onError={() => onVideoError()}
            width={'100%'}
            height={'100%'}
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="absolute left-0 top-0 h-full w-full bg-contain bg-center object-cover"
          >
            <source type="video/mp4" src="/assets/weeded-v4.mp4" />
          </video>
        </div>
        <div style={{ padding: '84px 132px' }} className="absolute hidden h-full w-full">
          <p>Cool Buttons Go Here</p>
        </div>
      </div>
    </div>
  );
}

export default Landing;
