import './LoadingCircles.css';

function LoadingCircles() {
  return (
    <div className="loader-4 center">
      <span></span>
    </div>
  );
}

export default LoadingCircles;
